import React, { CSSProperties, SyntheticEvent } from 'react';
import { Image } from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';

import IconFb from '../../../assets/icons/iconFb.svg';
import IconGoogle from '../../../assets/icons/iconGoogle.svg';
import IconApple from '../../../assets/icons/iconApple.svg';

type SignInFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  loading: boolean;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as CSSProperties['flexDirection'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    maxHeight: 47,
  },
  buttonIcon: {
    width: '8%',
  },
  buttonText: {
    fontWeight: 'bold',
    width: '92%',
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '1rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
};

export default function SignInForm({
  errorInformation,
  // onClickFacebookLogin,
  onClickGoogleLogin,
  onClickAppleLogin,
  setEmail,
  setPassword,
  setCurrentVariant,
  loading,
}: SignInFormProps) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        onClick={onClickGoogleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconGoogle} />
        <Text sx={Style.buttonText}>{t('LOGIN_GOOGLE')}</Text>
      </Button>

      <Button
        onClick={onClickAppleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconApple} />
        <Text sx={Style.buttonText}>{t('LOGIN_APPLE')}</Text>
      </Button>

      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
        disabled={loading}
      />

      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        required
        overlayLink={
          <Button
            type="button"
            variant="textGrey"
            sx={{ fontSize: 1 }}
            onClick={(): void => setCurrentVariant('resetPassword')}
          >
            {t('VERGESSEN')}
          </Button>
        }
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
        disabled={loading}
      />

      <Button type="submit" style={Style.loginButton} disabled={loading}>
        {t('EINLOGGEN')}
      </Button>
      <Button
        as="a"
        style={Style.loginButton}
        href="/register"
      >
        {t('FREE_REGISTER_BUTTON')}
      </Button>
    </React.Fragment>
  );
}
